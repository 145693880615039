:root {
  --color1: #fefefe;
  --color2: #e7ebf0;
  --color3: #c1b3a5;
  --color4: #9d9083;
  --color5: #504e55;
  --color6: #BFEA7C;
  --color7: #12372A;
  --color8: #1877F2;
  --color9:#2a2762;
  --color10: #25d366;
  --ok-color: #198038;
  --error-color: #570408;
  --header-height: 8vh;
  --content-height: 92vh;
  --title-desktop: 3rem;
  --title-tablet: 2.5rem;
  --title-mobile: 2rem;
  --subtitle-desktop: 2.5rem;
  --subtitle-tablet: 2rem;
  --subtitle-mobile: 1.75rem;
  --section-desktop: 2rem;
  --section-tablet: 1.75rem;
  --section-mobile: 1.5rem;
  --body-desktop: 1.125rem;
  --body-tablet: 1rem;
  --body-mobile: 0.875rem;
  --small-desktop: 0.875;
  --small-tablet: 0.75rem;
  --small-mobile: 0.625rem;
  --smallest-desktop: 0.75rem;
  --smallest-tablet: 0.625rem;
  --smallest-mobile: 0.5rem;
  --button-desktop: 1rem;
  --button-tablet: 0.875rem;
  --button-mobile: 0.75rem;
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body * {
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  box-sizing: border-box;
}