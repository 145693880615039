.content {
    width: 100%;
    padding: 0 15vw;
}

@media (max-width: 767px) { 
    .content {
        width: 100%;
        padding: 0 10vw;
    }
}