.about-us {
  width: 100%;
  margin-top: 10vh;
}

.swiper {
  width: 100%;
}

.swiper-slide {
  border-radius: 20px;
}

.mySwiper .swiper-slide img {
  height: 200px;
}

@media (max-width: 767px) {
  .about-us {
    overflow: hidden;
  }

  .mySwiper .swiper-slide img {
    height: 150px;
  }

  .mySwiper .swiper-slide {
    height: 70vh;
  }
}