.about-box {
    padding: 20px;
    background: linear-gradient(to left bottom, rgb(182, 244, 146), rgb(51, 139, 147));
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.about-box-img {
    /*border-radius: 25% 58% 69% 25% / 25% 64% 25% 68%;*/
    border-radius: 10px;
    box-shadow: rgb(191, 234, 124) 20px 20px;
}

.about-box-text-box {
    padding: 20px;
}

.about-box-text {
    color: var(--color1);
    font-size: var(--section-desktop);
}

.deslizar {
  display: none;
}

@media (max-width: 767px) {
    .about-box {
        flex-wrap: wrap;
    }

    .about-box-text-box {
        padding: 10px;
    }
    
    .about-box-text {
        font-size: var(--body-desktop);
    }

    .about-box-img {
        box-shadow: rgb(191, 234, 124) 10px 10px;
    }

    .deslizar {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 2rem;
        color: var(--color6);
    }

    .deslizar p {        
        font-size: var(--body-mobile);
        margin: 0;
    }
}