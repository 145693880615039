.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10vh;
}

.footer * {
    margin: 0;
}

.footer-main-info {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background-color: var(--color6);
    padding: 20px 20px 0 20px;
    border-bottom: 10px solid var(--color1);
}

.footer-main-section {
    width: 40%;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
}

.company {
    width: 100%;
    padding: 5px;
    font-size: var(--section-desktop);
}

.dir {
    width: 100%;
    padding: 5px;
}

.footer-contact {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.mail {
    padding: 5px 50px 5px 5px;
}

.tel {
    padding: 5px 50px 5px 5px;
}

.ws {
    padding: 5px 50px 5px 5px;
}

.info-title {
    font-size: var(--small-desktop);
    margin-bottom: 5px;
    color: var(--color1);
}

.info-desc {
    font-size: var(--small-desktop);
    font-weight: 500;
}

.footer-secondary-info {
    width: 100%;
    padding: 15px 5px;
    border-top: 1px solid var(--color1);
    margin-top: 20px;
}

.footer-secondary-info p {
    font-size: var(--smallest-desktop);
}

@media (max-width: 767px) {
    .footer-secondary-info p {
        font-size: var(--smallest-mobile);
    }

    .company {
        font-size: var(--section-mobile);
    }

    .info-title {
        font-size: var(--small-mobile);
    }

    .info-desc {
        font-size: var(--small-mobile);
    }
}

/*.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10vh;
}

.footer-main-info {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    background-color: var(--color6);
}

.footer-section {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-secondary-info {
    width: 100%;
    padding: 20px;
    background-color: var(--color2);
}

.footer p {
    margin: 0;
    font-size: var(--small-desktop);
    font-weight: 600;
}

.footer-icon {
    font-size: 2rem;
    color: var(--color8);
}

.contact-table {
    margin-bottom: 10px;
    font-size: var(--small-desktop);
}

.table-column {
    padding: 10px 10px 10px 0;
}

.contact-subtable {
    border: none;   
}

.contact-subtable td {
    border: none;
    padding: 0;
    margin: 0;
    padding-right: 10px;
}

@media (max-width: 767px) {
    .footer p {
        font-size: var(--small-mobile);
    }

    .contact-table {
        font-size: var(--small-mobile);
    }
}*/