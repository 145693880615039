.header {
    height: var(--header-height);
    width: 100%;
    padding: 0 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color2);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: var(--color1);
}

.logo {
    font-size: var(--section-desktop);
}

.nav {
    display: flex;
    font-size: var(--body-desktop);
    font-weight: 600;
    align-items: center;
}

.link {
    margin: 0 10px;
    transition: .2s ease-in-out all;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.link:hover {
    transform: scale(1.05);
    transition: .2s ease-in-out all;
    cursor: pointer;
}

.mobile-nav {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    background-color: var(--color1);
    width: 30%;
    height: 100vh;
    border-left: 1px solid var(--color2);
    transition: 1s ease-in-out all;
}

.mobile-nav .link{
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 5vh;
}

.menu-box {
    display: none;
    font-size: 2rem;
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 100;
    color: var(--color9);
}

.not-visible {
    right: -30%;
    transition: 1s ease-in-out all;
}

.remark {
    background-color: var(--color6);
    display: flex;
    align-items: center;
    justify-self: center;
    border-radius: 10px;
    padding: 10px;
    height: 3rem;
}

@media (max-width: 767px) {
    .header {
        padding: 10px;
    }

    .mobile-nav {
        width: 80%;
        display: flex;
        flex-direction: column;
    }

    .nav {
        display: none;
    }

    .menu-box {
        display: block;
    }

    .not-visible {
        right: -90%;
    }
}

@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}