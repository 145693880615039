.services {
    display: flex;
    flex-wrap: wrap;
}

.services-boxes {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}