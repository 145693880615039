.contact {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10vh;
}

.contact-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-right: 20px;
}

.contact-info p {
    margin: 0;
}

.contact-map {
    width: 50%;
    border-radius: 20px;
    overflow: hidden;
}

.contact-info-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 20px 0;
}

.input-group {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--color2);
    margin: 5px 0;
}

.icon-box {
    padding: 20px;
    display: flex;
    align-items: center;
    max-width: 10%;
}

.input-box {
    width: 90%;
}

.input-box label {
    font-size: 13px;
    transition: .2s ease-in-out all;
}

.input {
    margin: 0;
    padding: 0;
    height: 40px;
    display: flex;
    align-items: center;
    border: none;
    width: 100%;
    font-size: var(--body-desktop);
}

.input:focus {
    border: none;
    outline: none;
}

.form {
    padding: 20px;
    border: 1px solid var(--color2);
    border-radius: 20px;
}

.form-title p {
    margin-bottom: 20px;
    font-size: var(--body-desktop);
    font-weight: 600;
    text-align: center;
}

.form-subtitle p {
    font-size: var(--body-desktop);
    font-weight: 600;
    text-align: center;
    margin: 5px 0 5px 0;
}

.form button {
    width: 100%;
    padding: 5px;
}

.non-empty {
    color: var(--color6);
    transition: .2s ease-in-out all;
}

.textarea {
    height: 100px;
}

.alert {
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
}

.alert * {
    margin: 5px;
}

.success {
    color: var(--ok-color);
    background-color: rgba(25, 128, 56, .3);
    border: 1px solid var(--ok-color);
}

.error {
    color: var(--error-color);
    font-size: var(--small-desktop);
}

.icon {
    font-size: 1.2rem;
    width: 10%;
}

.btn-form:hover {
    transform: scale(1.01);
}

.socials {
    font-size: 2rem;
    /*background: linear-gradient(to left bottom, rgb(182, 244, 146), rgb(51, 139, 147));*/
    background-color: var(--color2);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 10px;
    height: 3rem;
    border: 1px solid var(--color2);
    transition: .2s ease-in-out all;
}

.socials a {
    display: flex;
}

.socials:hover {
    transform: scale(1.01);
    transition: .2s ease-in-out all;
    cursor: pointer;
}

.wa-icon {
    margin-right: 10px;
    color: var(--color10);
    transition: .2s ease-in-out all;
}

.wa-icon:hover {
    transform: scale(1.05);
    transition: .2s ease-in-out all;
    cursor: pointer;
}

.fb-icon {
    color: var(--color8);
    font-size: 1.75rem;
    transition: .2s ease-in-out all;
}

.fb-icon:hover {
    transform: scale(1.05);
    transition: .2s ease-in-out all;
    cursor: pointer;
}

.enviar{
    height: 2.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s ease-in-out all;
}

.success-icon {
    font-size: 2rem;
    transition: .2s ease-in-out all;
}

.error-msg {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.error-msg p {
    margin: 0;
    padding: 0;
}

@media (max-width: 767px) {
    .contact-info {
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
    }

    .contact-map {
        width: 100%;
        height: 50vh;
    }

    .input {
        font-size: var(--body-mobile);
    }
    
    .form-title p {
        margin-bottom: 20px;
        font-size: var(--body-mobile);
        font-weight: 600;
        text-align: center;
    }

    .form-subtitle p {
        font-size: var(--body-mobile);
        font-weight: 600;
        text-align: center;
        margin: 5px 0 5px 0;
    }

    .icon-box {
        padding: 0;
        width: 10%;
    }
}