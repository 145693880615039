.logo-img {
    height: 3rem;
}

.logo2 {
    height: 3rem;
}

@media (max-width: 767px) {
    .logo2 {
        height: 2rem;
    }
}