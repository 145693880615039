.service-box {
    max-width: 30%;
    border: 1px solid var(--color2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-radius: 20px;
    margin: 10px 0;
}

.service-box-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.service-box-img {
    width: 50%;
}

.service-box-title {
    font-size: var(--body-desktop);
    text-align: center;
    font-weight: 600;
}

.service-box-subtitle {
    font-size: var(--small-desktop);
    text-align: center;
}

.service-box-text {
    font-size: var(--small-desktop);
}

.service-box-icon-box {
    padding: 20px;
    background: linear-gradient(to left bottom, rgb(182, 244, 146), rgb(51, 139, 147));
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-box-icon {
    font-size: 5rem;
    color: var(--color6);
}

.service-box-icon-box img {
    height: 5rem;
}

@media (max-width: 767px) {
    .service-box {
        max-width: 100%;
        padding: 10px;
    }

    .service-box-title {
        font-size: var(--body-mobile);
    }
    
    .service-box-subtitle {
        font-size: var(--small-mobile);
    }
    
    .service-box-text {
        font-size: var(--small-mobile);
    }

    .service-box-icon {
        font-size: 3rem;
    }
}