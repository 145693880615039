.main {
    margin-top: var(--header-height);;
    width: 100%;
    height: var(--content-height);
    display: flex;
    justify-content: center;
    align-items: center;
}

.images-box {
    /*background-image: linear-gradient(to top right, rgba(255, 239, 186, .3), rgb(255, 255, 255, .5)), url("../images/fondo.jpg");
    background-image: linear-gradient(to top right, rgba(211, 204, 227, .3), rgb(233, 228, 240, .5)), url("../images/fondo.jpg");*/
    background-image: linear-gradient(112.1deg, rgba(32, 38, 57, .5) 11.4%, rgba(63, 76, 119, .5) 70.2%), url("../images/fondo.jpg");
    background-size: cover;
    background-position: center;
    transition: background-image 1s ease-in-out;
    width: 100%;
    height: calc(var(--content-height) - 10vh);
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column-reverse;
}

.main-info-box {
    max-width: 60%;
    margin: 20px 0;
}

.main-info-box-buttons {
    max-width: 60%;
    margin: 20px 0 0 0;
}

.company-name {
    font-size: var(--title-desktop);
    font-weight: 500;
    color: var(--color1);
    margin: 0;
}

.description {
    font-size: var(--section-desktop);
    color: var(--color1);
    margin: 0;
}

.btn-contact {
    margin-right: 5px;
}

@media (max-width: 767px) {
    .description {
        font-size: var(--section-mobile);
    }
    
    .main-info-box {
        max-width: 100%;
    }

    .company-name {
        font-size: var(--title-mobile);
    }

    .btn-contact {
        margin-right: 0;
        margin-bottom: 5px;
    }
}