.btn {
    font-size: var(--body-desktop);
    font-weight: 600;
    border-radius: 10px;
    border: none;
    padding: 15px 20px;
    transition: .2s ease-in-out all;
}

.btn:hover {
    transform: scale(1.05);
    transition: .2s ease-in-out all;
    cursor: pointer;
}

.btn-semi-dark {
    background-color: var(--color6);
    color: var(--color7);
}

.btn-semi-light {
    background-color: var(--color2);
    color: var(--color7);
}

.btn-semi-light:hover {
    background-color: var(--color6);
    color: var(--color7);
}

.btn-light {
    background-color: var(--color1);
    color: var(--color7);
}

.title {
    width: 100%;
}

@media (max-width: 767px) {
    .btn {
        font-size: var(--body-mobile);
    }
}